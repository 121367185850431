import request from '@/request/interceptors'

// 获取手机验证码
export function getVCodeWhiteApi(data) {
  return request({
    url: 'service/other/notice/v1/send/sms/vCode/white',
    method: 'post',
    data
  })
}

// 获取消费记录
export function getTradeRecordApi(params) {
  return request({
    url: 'service/marketing/memberCard/tradeRecord/h5/v1/page',
    method: 'get',
    params
  })
}

// 获取充值套餐
export function getChargePackageApi(params) {
  return request({
    url: 'service/marketing/memberCard/recharge/v1/packages',
    method: 'get',
    params
  })
}

// 微信支付
export function wxPayApi(data) {
  return request({
    url: 'service/marketing/memberCard/recharge/v1/wx/pay',
    method: 'post',
    data
  })
}